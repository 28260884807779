import React from "react";
import Seo from "./seo.js";
import NavBar from "./navbar.js";
import Footer from "./footer.js";
import Background from "./background.js";

import "../styles/index.css";
import "../styles/w3.css";

export default (props) => (
	<Background>
		<div className={"w3-text-white scroll-wrapper"}>
			<Seo i={props} />
			<NavBar />
			<br />
			<br />
			<br />
			<br />

			<div>{props.children}</div>

			<Footer />
		</div>
	</Background>
);
